import React from "react";
import { Link } from 'react-router-dom';
import logoImg from '../assets/img/TSC-plain.png'

const ForgotPassword = () => {

  return (
        <div className="row">
            <div className="col-md-7 col-lg-8 col-xl-7">
                <div style={{paddingRight: '20px', paddingLeft: '30px', paddingTop: '50px'}}>
                    <div className="container" style={{marginTop: '30px'}}>
                        <div className="flex-shrink-1" style={{maxWidth: '500px'}}>
                            <h1 style={{fontFamily: 'Lato, sans-serif', fontSize: '40px', color: 'rgb(0,0,0)', paddingTop: '20px', paddingBottom: '20px', fontWeight: 'bold'}}>
                              Welcome to
                              The Scholars Careers
                            </h1>
                            <p style={{fontFamily: 'DM Sans, sans-serif', paddingBottom: '10px', color: 'rgb(137,137,137)'}}>
                              It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                            </p>
                            <form style={{marginTop: '40px', marginBottom: '30px'}}>
                              <input className="form-control form-control-lg" type="text" style={{marginBottom: '10px', fontSize: '14px', fontFamily: 'DM Sans, sans-serif', borderColor: 'rgb(234,234,234)', maxWidth: '380px'}} placeholder="Type your email..." />
                                <div className="d-grid gap-2">
                                  <button className="btn btn-primary" type="button" style={{marginTop: '20px', fontFamily: 'DM Sans, sans-serif', fontSize: '18px', maxWidth: '380px', background: 'rgb(0,0,0)', borderColor: 'rgb(255,255,255)'}}>
                                    Reset Password
                                  </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col" style={{height: '50rem', background: '#000000', display: 'flex'}}>
              <Link to="/login" className="btn btn-primary btn-lg position-absolute top-0 end-0" style={{borderColor: 'rgb(255,255,255)', color: 'rgb(255,255,255)', fontSize: '18px', fontFamily: 'DM Sans, sans-serif', marginTop: '30px', marginRight: '30px', background: '#000000'}}>Login</Link>
              <img src={logoImg} width="580px" height="160px" style={{paddingRight: '60px', paddingLeft: '60px', alignSelf: 'center'}} />
            </div>

        </div> 
  );
}

export default ForgotPassword;