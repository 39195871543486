import React, { useEffect, useState } from "react";
const hardcodeData = require('./holland_test.json');

import "./HollandTest.css"

const HollandTest = () => {

  const [result, setResult] = useState({
    data: {},
    loaded: false,
    placeholder: 'Loading'
  });
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [nrQuestions, setNrQuestions] = useState(0);
  
  useEffect(async () => {

    const res = await fetch("get_example_test/");

    /*
    if (res.status > 400) {
      setResult({
        data: null,
        loaded: false,
        placeholder: "Something went wrong!"
      });
      return null;
    }
    */
    
    const data =  await res.json(); //hardcodeData;
    setResult({
      data,
      loaded: true,
      placeholder: "Data fetched"
    });

    setNrQuestions(data.nr_questions);

  }, []);
  
  if (!result.loaded || nrQuestions < 1) {
    return null;
  }

  const questions = result.data.questions;
  const options = result.data.options.length > 0 ? result.data.options : [];

  return (
    <section>
      <div className="container" style={{marginTop: '20px'}}>
        <div className="row" style={{marginBottom: '10px'}}>
            <div className="col-md-12" style={{marginTop: '100px'}}>
                <div className="card">
                    <div className="card-body">
                      
                      <h5 className="card-title">Question {currentQuestion}</h5>
                      <hr style={{marginTop: '10px', marginBottom: '20px' }} />
                      <h6 className="text-muted card-subtitle mb-2" style={{fontFamily: 'DM Sans, sans-serif'}}>Self Observation (?)</h6>
                      <p className="card-text" style={{fontFamily: 'DM Sans, sans-serif'}}>{questions[currentQuestion - 1].question}</p>
                      <div className="options">{
                        options.map( option => {
                          return (
                            <div className="option" style={{fontFamily: 'DM Sans, sans-serif'}} key={option}>
                              <input className="option-input" type="radio" id="formCheck-2" style={{fontFamily: 'DM Sans, sans-serif'}}/>
                              <label className="option-label" style={{fontFamily: 'DM Sans, sans-serif'}}>{option}</label>
                            </div>
                          )
                        })
                      }
                      </div>
                      <div className="text-center" style={{marginTop: '50px'}}>
                        <button disabled={currentQuestion == 1} onClick={() => setCurrentQuestion(currentQuestion - 1)} className="btn btn-primary btn-sm" type="button" style={{fontFamily: 'DM Sans, sans-serif',borderRadius: '2px', background: 'rgb(255,255,255)', borderColor: 'rgb(0,0,0)', color: 'rgb(0,0,0)', marginRight: '10px', fontSize: '18px'}}>
                          <i className="fas fa-chevronLeft" style={{marginRight: '2px'}}></i>
                          Back
                        </button>
                        <button disabled={currentQuestion == nrQuestions} onClick={() => setCurrentQuestion(currentQuestion + 1)} className="btn btn-primary btn-sm" type="button" style={{fontFamily: 'DM Sans, sans-serif',borderRadius: '2px', background: 'rgb(255,255,255)', borderColor: 'rgb(0,0,0)', color: 'rgb(0,0,0)', marginRight: '10px', fontSize: '18px'}}>
                          Next
                          <i className="fas fa-chevronRight" style={{marginRight: '2px', fontSize: '18px'}}></i>
                        </button>
                      </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </section>
  );
}

export default HollandTest;