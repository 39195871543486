import React from "react";
import Image from '../components/elements/Image';
import logoImg from '../assets/img/TSC-plain.png'
import googleImage from '../assets/img/google-logo-png-suite-everything-you-need-know-about-google-newest-0.png';
import { Link } from 'react-router-dom';

const Register = () => {

  return (
        <div className="row">
            <div className="col-md-7 col-lg-8 col-xl-7">
                <div style={{paddingRight: '20px', paddingLeft: '30px', paddingTop: '50px'}}>
                    <div className="container" style={{marginTop: '30px'}}>
                        <div className="flex-shrink-1" style={{maxWidth: '500px'}}>
                            <h1 style={{fontFamily: 'Lato, sans-serif', fontSize: '40px', color: 'rgb(0,0,0)', paddingTop: '20px', paddingBottom: '20px', fontWeight: 'bold'}}>
                              Welcome to&nbsp;
                              The Scholars Careers
                              Registration Interface
                            </h1>
                            <p style={{fontFamily: 'DM Sans, sans-serif', paddingBottom: '10px', color: 'rgb(137,137,137)'}}>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                            <form style={{marginTop: '40px', marginBottom: '30px'}}>
                                <div className="card" style={{maxWidth: '380px', marginBottom: '20px'}}>
                                    <div className="card-body" style={{paddingTop: '10px', paddingBottom: '10px'}}>
                                        <div className="text-center">
                                          <Image src={googleImage} width={'10%'} />
                                          <a href="#" style={{color: 'var(--bs-dark)', fontFamily: 'DM Sans, sans-serif', paddingLeft: '15px'}}>
                                            Register with Google
                                          </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center" style={{maxWidth: '380px', marginBottom: '20px'}}>
                                  <small className="form-text text-center" style={{maxWidth: '380px', fontFamily: 'DM Sans, sans-serif'}}>
                                    Or use your email
                                  </small>
                                </div>
                                <input className="form-control form-control-lg" type="text" style={{marginBottom: '10px', fontSize: '14px', fontFamily: 'DM Sans, sans-serif', borderColor: 'rgb(234,234,234)', maxWidth: '380px'}} placeholder="Type your email..." />
                                <input className="form-control form-control-lg" type="password" style={{marginBottom: '10px', fontFamily: 'DM Sans, sans-serif', fontSize: '14px', borderColor: '#eaeaea', maxWidth: '380px'}} placeholder="Choose your password" />
                                <div className="text-start" style={{marginTop: '10px', maxWidth: '380px'}}>
                                  <Link to="/terms-conditions" style={{fontSize: '14px', fontFamily: 'DM Sans, sans-serif', paddingLeft: '25px', color: '#000000'}}>Read Terms and Conditions</Link>
                                </div>
                                <div className="form-check" style={{maxWidth: '380px', marginTop: '10px'}}>
                                  <input className="form-check-input" type="checkbox" id="formCheck-1" />
                                  <label className="form-check-label" style={{fontFamily: 'DM Sans, sans-seri', fontSize: '14px'}}>
                                    Accept our Terms and Conditions
                                  </label>
                                </div>
                                <div className="d-grid gap-2">
                                  <button className="btn btn-primary" type="button" style={{marginTop: '20px', fontFamily: 'DM Sans, sans-serif', fontSize: '18px', maxWidth: '380px', background: 'rgb(0,0,0)', borderColor: 'rgb(255,255,255)'}}>
                                    Get Started
                                  </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col" style={{height: '50rem', background: '#000000', display: 'flex'}}>
              <Link to="/login" className="btn btn-primary btn-lg position-absolute top-0 end-0" style={{borderColor: 'rgb(255,255,255)', color: 'rgb(255,255,255)', fontSize: '18px', fontFamily: 'DM Sans, sans-serif', marginTop: '30px', marginRight: '30px', background: '#000000'}}>Login</Link>
              <img src={logoImg} width="580px" height="160px" style={{paddingRight: '60px', paddingLeft: '60px', alignSelf: 'center'}} />
            </div>
        </div>
        
  );
}

export default Register;