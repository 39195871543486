import React from 'react';
import { Link } from 'react-router-dom';

import logoImg from '../../assets/img/TSC-plain.png'
import flagImg from '../../assets/img/flag-round-250.png'

import "./Footer.css"

const Footer = () => {

  return (
    <footer className="page-footer" style={{paddingTop: '20px'}}>
      <div className="container"><a href="/"><img src={logoImg} width="150px" style={{marginBottom: '20px'}} /></a>
          <p style={{fontSize: '13px'}}>The Scholars Careers (TSC)</p>
          <hr />
          <div className="footer-legal" style={{marginBottom:'20px'}}>
              <div className="float-md-end region"><a href="#" style={{fontSize: '13px'}}><img src={flagImg} width="5%" />Nigeria</a></div>
              <div className="d-inline-block copyright">
                  <p className="d-inline-block" style={{fontSize: '13px'}}>Copyright © 2021. All rights reserved.<br /></p>
              </div>
              <div className="d-inline-block legal-links">
                  <div className="d-inline-block item">
                      <h5 style={{fontSize: '13px'}}><Link to="/privacy">Privacy Policy</Link></h5>
                  </div>
                  <div className="d-inline-block item">
                      <h5 style={{fontSize: '13px'}}><Link to="/terms-conditions">Terms of Use</Link></h5>
                  </div>
                  <div className="d-inline-block item">
                      <h5 style={{fontSize: '13px'}}><Link to="/contact">Contact</Link></h5>
                  </div>
              </div>
          </div>
      </div>
  </footer>
  );
}

export default Footer;