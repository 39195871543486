import React, { useEffect, useState } from "react";

const Test = () => {

  const [result, setResult] = useState({
    data: [],
    loaded: false,
    placeholder: 'Loading'
  });

  useEffect(async () => {

    const res = await fetch("api/lead/");
    if (res.status > 400) {
      setResult({
        data: [],
        loaded: false,
        placeholder: "Something went wrong!"
      });
      return null;
    }

    const data = await res.json();
    setResult({
      data: data,
      loaded: true,
      placeholder: ""
    });
    
  }, []);

  return (
    <section>
      <div className="container">
        <div
          className="section-inner"
        >
          <p>List of contacts below</p>
          <ul>
            {result && result.data.map(contact => {
              return (
                <li key={contact.id}>
                  {contact.name} - {contact.email}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </section>

    
  );
}

export default Test;