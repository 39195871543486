import React from 'react';
import Accordion from 'react-bootstrap/Accordion'
import { Link } from 'react-router-dom';

import studentsImg from '../assets/img/4002302.jpg'
import escalatorImg from '../assets/img/29.jpg'

const Home = () => {

  return (
    <>
        <section style={{background: "url("+studentsImg+") top / contain no-repeat, #f2f2f0", paddingTop: '50px', maxHeight: '500px' }}>
            <div className="container" style={{paddingTop: '30px', paddingBottom: '20px'}}>
                <div className="row">
                    <div className="col-lg-4">
                        <div>
                            <h1 id="header-text">Awake the<br/>Greatness <br/>in You<br/></h1>
                            <p style={{color: '#000000'}}>Welcome to The Scholars Career<br/><strong>#1 Leading&nbsp;Nigerian</strong><br/>Career Advancement Platform.<br/></p>
                            <Link to="/register" className="btn btn-primary btn-lg" style={{backgroundColor: 'transparent', color: '#000000', borderColor: '#000000'}}>Get Started</Link>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div style={{paddingTop: '250px'}}></div>
                    </div>
                    <div className="col"></div>
                </div>
            </div>
        </section>
        <section>
            <div className="container" style={{marginBottom: '0px'}}>
                <div className="row" style={{marginBottom: '0px'}}>
                    <div className="col-md-6 col-lg-6 col-xl-5" style={{background: '#ffffff', borderRadius: '5px', marginBottom: '0px'}}>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header >About TSC</Accordion.Header>
                                <Accordion.Body>
                                    <p className="mb-0">Our platform enables students/individuals discover their ideal career path by identifying their strengths and weaknesses in tune with their talent and ambition....</p>
                                    <div style={{marginBottom: '0px'}}>
                                        <Link to="/about-us" style={{color: 'rgb(52,52,52)'}}>Read more</Link>
                                    </div>                                
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item  eventKey="1">
                                <Accordion.Header >Blog Article</Accordion.Header>
                                <Accordion.Body>
                                    <p className="mb-0"><strong>WHAT IS A CAREER?</strong><br/><br/>" You know what the greatest tragedy is in the whole world?... It's all the people who never find out what it is they want to do or what it is they're good at....</p>
                                    <div style={{marginBottom: '0px'}}>
                                        <Link to="/blog" style={{color: 'rgb(52,52,52)'}}>Read more</Link>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        
                    </div>
                    <div className="col-md-4 col-lg-6 col-xl-7" style={{marginBottom: '0px'}}><small className="form-text">Video Here</small>
                        <div className="text-center"><iframe allowFullScreen="" frameBorder="0" src="https://www.youtube.com/embed/bp0QrTSqqgI?autoplay=1&amp;mute=1" width="100%" height="315"></iframe></div>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <hr/>
        </section>
        <section style={{background: '#ffffff'}}>
            <div className="container" style={{paddingTop: '0px',paddingBottom: '0px',marginBottom: '0px',marginTop: '0px'}}>
                <div className="row" style={{marginBottom: '0px'}}>
                    <div className="col-lg-6" style={{background: 'rgba(167,168,169,0)', marginBottom: '0px'}}>
                        <section id="step-3" className="py-5" style={{paddingTop: '10px',paddingBottom: '10px'}}>
                            <div className="container py-3" style={{paddingTop: '0px',paddingBottom: '0px'}}>
                                <div className="row">
                                    <div className="col-xl-9 mx-auto" style={{marginBottom: '0px'}}>
                                        <ul className="list-group">
                                            <li className="list-group-item d-flex ps-0 border-0">
                                                <div className="d-flex flex-column flex-shrink-0 justify-content-between align-items-center"><span className="text-white"><i className="far fa-edit bg-primary p-2 rounded-circle"></i></span><span className="text-primary"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20" fill="none" className="fs-4 mb-2">
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M14.7071 12.2929C15.0976 12.6834 15.0976 13.3166 14.7071 13.7071L10.7071 17.7071C10.3166 18.0976 9.68342 18.0976 9.29289 17.7071L5.29289 13.7071C4.90237 13.3166 4.90237 12.6834 5.29289 12.2929C5.68342 11.9024 6.31658 11.9024 6.70711 12.2929L9 14.5858L9 3C9 2.44772 9.44772 2 10 2C10.5523 2 11 2.44772 11 3L11 14.5858L13.2929 12.2929C13.6834 11.9024 14.3166 11.9024 14.7071 12.2929Z" fill="currentColor"></path>
                                                        </svg></span></div>
                                                <div className="ms-3">
                                                    <h6 className="text-uppercase mb-1"><strong>Registration</strong><br/></h6>
                                                    <p className="text-muted" style={{color: 'var(--bs-gray-800)'}}>Register your school with us. Each student will have his/her own profile and you have a central profile to manage them all.<br/></p>
                                                </div>
                                            </li>
                                            <li className="list-group-item d-flex ps-0 border-0">
                                                <div className="d-flex flex-column flex-shrink-0 justify-content-between align-items-center"><span className="text-white"><i className="fas fa-chess bg-primary p-2 rounded-circle"></i></span><span className="text-primary"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20" fill="none" className="fs-4 mb-2">
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M14.7071 12.2929C15.0976 12.6834 15.0976 13.3166 14.7071 13.7071L10.7071 17.7071C10.3166 18.0976 9.68342 18.0976 9.29289 17.7071L5.29289 13.7071C4.90237 13.3166 4.90237 12.6834 5.29289 12.2929C5.68342 11.9024 6.31658 11.9024 6.70711 12.2929L9 14.5858L9 3C9 2.44772 9.44772 2 10 2C10.5523 2 11 2.44772 11 3L11 14.5858L13.2929 12.2929C13.6834 11.9024 14.3166 11.9024 14.7071 12.2929Z" fill="currentColor"></path>
                                                        </svg></span></div>
                                                <div className="ms-3">
                                                    <h6 className="text-uppercase mb-1"><strong>Psychometric testing</strong><br/></h6>
                                                    <p className="text-muted" style={{color: 'var(--bs-gray-800)'}}>The student can take our world class psychometric tests which creates their psychometric profiles.<br/></p>
                                                </div>
                                            </li>
                                            <li className="list-group-item d-flex ps-0 border-0">
                                                <div className="d-flex flex-column flex-shrink-0 justify-content-between align-items-center"><span className="text-white"><i className="fas fa-graduation-cap bg-primary p-2 rounded-circle"></i></span><span className="text-primary"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20" fill="none" className="fs-4 mb-2">
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M14.7071 12.2929C15.0976 12.6834 15.0976 13.3166 14.7071 13.7071L10.7071 17.7071C10.3166 18.0976 9.68342 18.0976 9.29289 17.7071L5.29289 13.7071C4.90237 13.3166 4.90237 12.6834 5.29289 12.2929C5.68342 11.9024 6.31658 11.9024 6.70711 12.2929L9 14.5858L9 3C9 2.44772 9.44772 2 10 2C10.5523 2 11 2.44772 11 3L11 14.5858L13.2929 12.2929C13.6834 11.9024 14.3166 11.9024 14.7071 12.2929Z" fill="currentColor"></path>
                                                        </svg></span></div>
                                                <div className="ms-3">
                                                    <h6 className="text-uppercase mb-1"><strong>Careers and Universities matches</strong></h6>
                                                    <p className="text-muted" style={{color: 'var(--bs-gray-800)'}}>The students have their profile matched with multiple careers and universities according to their psychometric profile.<br/></p>
                                                </div>
                                            </li>
                                            <li className="list-group-item d-flex ps-0 border-0">
                                                <div className="d-flex flex-column flex-shrink-0 justify-content-between align-items-center"><span className="text-white"><i className="fas fa-hands-helping bg-primary p-2 rounded-circle"></i></span></div>
                                                <div className="ms-3">
                                                    <h6 className="text-uppercase mb-1"><strong>Counselling</strong></h6>
                                                    <p className="text-muted" style={{color: 'var(--bs-gray-800)'}}>Book a session with our qualified counsellors to help you streamline the suggested careers and find a suitable one.<br/></p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div className="col-sm-7 col-lg-6" style={{background: '#ffffff', borderTopRightRadius: '5px', borderBottomRightRadius: '5px', marginBottom: '0px'}}>
                        <div style={{paddingLeft: '30px',textAlign: 'center'}}><img src={escalatorImg} width="100%" />
                            <div className="card" style={{borderRadius: '2px'}}>
                                <div className="card-body">
                                    <h4 className="card-title" style={{marginBottom: '15px'}}><strong>Why Trust Us</strong></h4>
                                    <hr/>
                                    <p className="card-text" style={{textAlign: 'justify', color: 'var(--bs-gray-700)'}}><strong>Lorem Ipsum</strong>&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section style={{background: '#ffffff'}}>
            <div className="container" style={{paddingTop: '0px',paddingBottom: '0px',marginBottom: '10px',marginTop: '0px'}}>
                <div className="row" style={{marginBottom: '10px'}}>
                    <div className="col" style={{background: 'rgba(167,168,169,0)'}}>
                        <div className="text-start">
                            <section className="newsletter-subscribe">
                                <div className="container" id="subscribe">
                                    <div className="intro">
                                        <h2 className="text-center">Get Our Newsletter</h2>
                                    </div>
                                    <form className="d-flex justify-content-center flex-wrap" method="post">
                                        <div className="mb-3"><input className="form-control" type="email" name="email" placeholder="Your Email" /></div>
                                        <div className="mb-3"><button className="btn btn-primary" type="submit">Subscribe </button></div>
                                    </form>
                                </div>
                                <p className="text-center">Receive our informative weekly newsletter </p>
                            </section>
                        </div>
                    </div>
                    <div className="col" style={{background: '#000000', borderTopRightRadius: '5px', borderBottomRightRadius: '5px'}}>
                        <div style={{paddingLeft: '30px', marginBottom: '20px'}}>
                            <h4 style={{marginTop: '30px', marginBottom: '40px', color: 'white'}}><strong>Go ahead,</strong><br/><strong>Explore.</strong></h4>
                            <p style={{color: 'white'}}>Take the free assessment and uncover things you didn’t know about yourself.<br/></p>
                            <Link to="/register" className="btn btn-primary btn-lg" style={{marginBottom: '10px',marginTop: '20px',backgroundColor: 'transparent', borderColor: 'white'}}>Sign up now</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  );
}

export default Home;