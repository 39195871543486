import React from "react";

const ContactUs = () => {

  return (
    <section>
      <div className="container" style={{marginBottom: '10px'}}>
        <h3>Contact us</h3>
        <hr />
      </div>
      <div className="container">
          <div className="row">
              <div className="col-md-6">
                  <p style={{marginBottom: '5px'}}><i className="fa fa-phone-square"></i><strong>&nbsp;Phone Number:</strong>&nbsp;+234(0)</p>
                  <p><i className="fa fa-envelope-square"></i><strong>&nbsp;Email:</strong>&nbsp;contact@thescholarscareers.com</p>
              </div>
              <div className="col-md-6">
                  <p><i className="fa fa-map-marker"></i><strong>&nbsp;Address:</strong>&nbsp;Lagos, Nigeria</p>
              </div>
          </div>
      </div>
      <div className="container" style={{marginTop: '10px'}}>
          <div className="row">
              <div className="col-md-6">
                  <div className="vstack">
                      <section className="contact-clean">
                          <form method="post">
                              <h2 className="text-center" style={{fontSize: '18px'}}>Send us a Message</h2>
                              <div className="mb-3"><input className="form-control" type="text" name="name" placeholder="Name" /></div>
                              <div className="mb-3"><input className="form-control is-invalid" type="email" name="email" placeholder="Email" /><small className="form-text text-danger">Please enter a correct email address.</small></div>
                              <div className="mb-3"><textarea className="form-control" name="message" placeholder="Message" rows="14"></textarea></div>
                              <div className="mb-3"><button className="btn btn-primary" type="submit">send </button></div>
                          </form>
                      </section>
                  </div>
              </div>
              <div className="col-md-6">
                  <section className="map-clean">
                      <div className="container">
                          <div className="intro">
                              <h2 className="text-center" style={{fontSize: '18px'}}>Location </h2>
                              <p className="text-center">Address of The Scholars Careers:</p>
                          </div>
                      </div><iframe allowfullscreen="" frameborder="0" src="https://cdn.bootstrapstudio.io/placeholders/map.html" width="100%" height="450"></iframe>
                  </section>
              </div>
          </div>
      </div>
    </section>    
  );
}


export default ContactUs;