import React from "react";

const Blog = () => {

  return (
    <section>
      <div class="container">
        <h3>Blog Articles</h3>
        <hr/>
      </div>
      <div class="container">
        <div class="card">
            <div class="card-body">
                <article>
                    <h5>CHOOSING A CAREER</h5>
                    <hr/>
                    <p>" You know what the greatest tragedy is in the whole world?... It's all the people who never find out what it is they want to do or what it is they're good at. It's all the sons who become blacksmiths because their fathers were blacksmiths. It's all the people who never get to know what it is that they can be. It's all the wasted chances. ”<br/><br/><strong>― Terry Pratchett,</strong> <em>Moving Pictures</em><br/><br/></p>
                </article>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <article>
                    <h5>WHAT IS A CAREER?&nbsp;</h5>
                    <hr/>
                    <p>According to Wikipedia, The&nbsp;Oxford English Dictionary&nbsp;defines the word "career" as a&nbsp;person's "course or progress through&nbsp;life&nbsp;(or a distinct portion of life)". This definition relates "career" to a range of aspects of an individual's life, learning, and work. "Career" is also frequently understood&nbsp;to relate to the working aspects of an individual's life - as in "career woman", for example. A third way in which the term "career" is used describes an&nbsp;occupation&nbsp;or a&nbsp;profession&nbsp;that usually involves special training or formal education.<br/></p>
                </article>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <article>
                    <h5>WHY IS IT IMPORTANT TO CHOOSE A CAREER?</h5>
                    <hr/>
                    <p>Career choosing is one of the most important decisions one must make in life. It is so much essential than deciding what you will do to make a living. The importance of choosing a career with which we are satisfied and fulfilled cannot be overemphasized. Not all people are lucky enough to just know what they want to do and end up in their various satisfying careers without giving it much thought. So, in other not to end up being unhappy with life, you have to make a well-thought-out decision about choosing a suitable career.<br/></p>
                </article>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <article>
                    <h5>FOUR (4) KEY STEPS YOU NEED TO TAKE TO CHOOSE YOUR CAREER</h5>
                    <hr/>
                    <p><strong>1) DISCOVER YOURSELF:</strong><br/>To become successful in life, one has to discover his or herself. How?&nbsp;According to Adams and Marshall (1996), they established the four formations to discovery process:&nbsp;<br/><br/><i class="fa fa-check"></i>&nbsp;A structure and order to self-knowledge;<br/><i class="fa fa-check"></i>&nbsp;A sense of consistency and coherence to beliefs, goals, and self-knowledge;&nbsp;<br/><i class="fa fa-check"></i>&nbsp;A sense of continuity for one’s history and future; goals and direction;&nbsp;<br/><i class="fa fa-check"></i>&nbsp;A sense of personal control of their choices and outcomes.<br/><br/>Furthermore, thinking about where you are now, where you want to be and how you are going to get there are elements that brings you into the reality of yourself. Once you have thought about where you are now and where you want to be, you can work on getting to know your skills, interest, or value.<br/><br/><strong>2) DETERMINATION:&nbsp;</strong><br/>This step requires comparing your options, pointing out your choice, and thinking about what suits you best at the point in time.<br/><br/><strong>3) COMMITMENT:&nbsp;</strong><br/> This step requires you to put together what you need to do to put your plans into action. Use all you have to learn about your skills, interest, and value, together with the information you have gathered about your career of interest, and put them into action.<br/><br/><strong>4) FOCUS:&nbsp;</strong><br/>Sometimes in life, situations and circumstances will want to deny us from our rights and reality, but it's impossible to distract or stop a man who is focused on his mission. Jack Canfield said "successful people maintain a positive focus in life no matter what is going on around them".<br/></p>
                </article>
            </div>
        </div>
    </div>
  </section>    
  );
}


export default Blog;