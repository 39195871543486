import React from "react";
import Accordion from 'react-bootstrap/Accordion'

const AboutUs = () => {

  return (
    <section>
        <div class="container">
          <h3>About us</h3>
          <hr />
          <p>Our platform enables students/individuals discover their ideal career path by identifying their strengths and weaknesses in tune with their talent and ambition. We empower you when you think to make the next big move in your Academics or Career. Our focus remains on serving our clients and providing the best possible solution to them.<br/></p>
        </div>
        <div class="container">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Our History</Accordion.Header>
              <Accordion.Body>
                <p class="mb-0">After the careful observation of our society, we noticed that most times people get to a certain level in their careers and realise that this isn’t what they signed up for. When we looked further, we realised their choices were based upon what friends or parents thought they should do.<br/>The Scholars Career was founded to solve this problem. We have a team of skilled personnel that enable the smooth running of the program which is powered by Artificial Intellogence. Partnership with leading education, training and counselling organization will help move our users into the roles where their skill is most suited, and their aspirations are best met.<br /></p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Our Philosophy</Accordion.Header>
              <Accordion.Body>
                <p class="mb-0">The complete realization of one’s potential and the full development of one’s abilities is important. We do what we do because of how satisfying implementing this efficient, reliable yet simple program will help our client/student/individual/parents/schools make informed decision as it pertains to their education and careers.</p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Our Team</Accordion.Header>
              <Accordion.Body>
              <p class="mb-0">We are a group of like minded individuals from diverse background who have had careers across various industries. Trust us to help you figure out your true self and find your way to fulfilment and best career decisions.</p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
      </div>
    </section>    
  );
}

export default AboutUs;