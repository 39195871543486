import React from 'react';
import { Switch } from 'react-router-dom';
import AppRoute from './utils/AppRoute';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';
import LayoutNoHeader from './layouts/LayoutNoHeader';

// Pages 
import Home from './pages/Home';
import Test from './pages/Test';
import Login from './pages/Login';
import Register from './pages/Register';
import ForgotPassword from './pages/ForgotPassword';
import FreeTest from './pages/HollandTest';
import TermsConditions from './pages/TermsConditions';
import FAQ from './pages/FAQ';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import Blog from './pages/Blog';
import PrivacyPolicy from './pages/PrivacyPolicy';

const App = () => {

  document.body.classList.add('is-loaded')

  return (
    <Switch>
      <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
      <AppRoute exact path="/login" component={Login} layout={LayoutNoHeader} />
      <AppRoute exact path="/register" component={Register} layout={LayoutNoHeader} />
      <AppRoute exact path="/forgot-password" component={ForgotPassword} layout={LayoutNoHeader} />
      <AppRoute exact path="/terms-conditions" component={TermsConditions} layout={LayoutDefault} />
      <AppRoute exact path="/privacy" component={PrivacyPolicy} layout={LayoutDefault} />
      <AppRoute exact path="/faq" component={FAQ} layout={LayoutDefault} />
      <AppRoute exact path="/about-us" component={AboutUs} layout={LayoutDefault} />
      <AppRoute exact path="/contact" component={ContactUs} layout={LayoutDefault} />
      <AppRoute exact path="/blog" component={Blog} layout={LayoutDefault} />
      <AppRoute exact path="/free-test" component={FreeTest} layout={LayoutDefault} />
      <AppRoute exact path="/test" component={Test} layout={LayoutDefault} />
    </Switch>
  );
}

export default App;