import React from 'react';
import { Link } from 'react-router-dom';

import logoImg from '../../assets/img/TSC-plain.png'


const Header = ({
  className,
  ...props
}) => {

  return (
    <header
      {...props}
      className='header'
    >
     
      <nav className="navbar navbar-dark navbar-expand-md navigation-clean" style={{background: 'rgb(0,0,0)'}}>
        <div className="container"><a className="navbar-brand" href="/"><img src={logoImg} width="130px" /></a><button data-bs-toggle="collapse" className="navbar-toggler" data-bs-target="#navcol-2"><span className="visually-hidden">Toggle navigation</span><span className="navbar-toggler-icon"></span></button>
            <div className="collapse navbar-collapse" id="navcol-2" style={{marginBottom: '0px', marginTop: '10px'}}>
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <Link className="nav-link" style={{color: 'rgb(255,255,255)'}} to="/about-us">About us</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" style={{color: 'rgb(255,255,255)'}} to="/blog">Blog</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" style={{color: 'rgb(255,255,255)'}} to="/faq">FAQ</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" style={{color: 'rgb(255,255,255)'}} to="/contact">Contact Us</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" style={{color: 'rgb(255,255,255)'}} to="/login"><i className="fa fa-user-circle-o"></i>&nbsp;Login</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link border rounded border-white" style={{color: 'rgb(255,255,255)'}} to="/free-test">Sample Test</Link>
                </li>
              </ul>
            </div>
        </div>
    </nav>
  </header>
  );
}

export default Header;
