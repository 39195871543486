import React from "react";
import Accordion from 'react-bootstrap/Accordion'


const FAQ = () => {

  return (
    <section>
      <div className="container">
        <h3>Frequently Asked Questions (FAQ)</h3>
        <hr/>
    </div>
    <div className="container">
        <div className="row">
            <div className="col-md-6">
                <div className="vstack">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>What is a Career?</Accordion.Header>
                    <Accordion.Body>
                      <p className="mb-0">A career describes an individual’s journey through learning, work, and other aspects of life. The term career has several meanings. It can be viewed from different perspectives. It is a lifelong sequence of jobs. It is a sequence of positions that a person has held over has or will take in their life. (iEduNote.com)</p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>What is Counselling?</Accordion.Header>
                    <Accordion.Body>
                      <p className="mb-0">Counselling is the application of mental health, psychological or human development principles, through cognitive, affective, behavioural or systemic interventions, strategies that address wellness, personal growth, or career development, as well as pathology.<br/><br/><em><span style={{textDecoration: 'underline'}}>(http://counseling. org)</span></em><br/><br/>Counselling deals with wellness, personal growth, career, and pathological concerns. In other words, counsellors work in areas that involve relationships (Casey, 1996). These areas include intra- and interpersonal concerns related to finding meaning and adjustment in such settings as&nbsp;schools, families, and careers.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>What is Career Counselling?</Accordion.Header>
                    <Accordion.Body>
                      <p className="mb-0">Career counselling is getting the right guidance to help you make the career decisions you need to make now, but to give you the knowledge and skills you need to make future career and life decisions.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>What are Career Assessment?</Accordion.Header>
                    <Accordion.Body>
                      <p className="mb-0">Career assessments&nbsp;are tools that are designed to help individuals understand how a variety of personal attributes (i.e., data values, preferences, motivations, aptitudes and skills), impact their potential success and satisfaction with different career options and work environments.&nbsp;The scholars career is an online and offline career test, we offer both the quntitative and qualitative assessments. Quantitative assessment precisely measure key attributes believed to influence an individuals potential success and satisfaction with a career, while qualitative assessment is designed to help individuals clarify their goals and preferences, which can then be used to make more informed career decisions.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>What is Career Guidance?</Accordion.Header>
                    <Accordion.Body>
                      <p className="mb-0">Career Guidance takes the result and anlysis of career assessments and career counselling to the next level. Being successful in a career takes extensive efforts, and with an experienced coach/mentor, your career development efforts can find the right direction. The scholars career counsellors provide career guidance by building customized career action plans and road map, guide you to the right path, provide personalized 24/7 support, and help you attain a greater level in your caeer. </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>Who are Career Counsellors?</Accordion.Header>
                    <Accordion.Body>
                    <p className="mb-0">Career counselors are trained professionals that help uncover all the things we need to think about careers, along with providing&nbsp;tips that will hopefully lead us to making the right&nbsp;career choice per time.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>How can I get help Career help or Guidance?</Accordion.Header>
                    <Accordion.Body>
                    <p className="mb-0">The Scholars Career is available to help you on your journey of finding the right career that brings you satisfaction and helps you reach and maximize your potential. You can contact us on the available contacts on this site or talk to your career counsellor attached to your school.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="7">
                    <Accordion.Header>Career Counselling in Nigeria</Accordion.Header>
                    <Accordion.Body>
                    <p className="mb-0">Career counselling is a course studied in many university in Nigeria, but not many of the people who studied the course have the opportunity to a career guide. Over the years there has not been a sustainable career counselling programme to help students chose their career path in Nigeria. The scholars career is a platform that offers students an online and offline career assessments and career counselling in Nigeria, Africa and some other neighbouring countries. Be rest assured to the same level of services for both our offline and online services. </p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                </div>
            </div>
            <div className="col-md-6">
                <div className="text-center"><iframe src="https://www.instagram.com/p/Bzqnjr4AH3J/embed" allowtransparency="true" frameBorder="0" scrolling="no" width="320" height="550"></iframe></div>
            </div>
        </div>
      </div>
    </section>    
  );
}

export default FAQ;